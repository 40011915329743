<template>
    <el-dialog :title="method == 0 ? '新增问卷':'编辑问卷'" width="60%" :close-on-click-modal="false"
               :visible.sync="visible">
        <el-form size="small" :model="addEditForm" ref="addEditForm" label-width="140px" class="p_r2">
            <el-form-item label="问卷标题：" prop="questionnaireName"
                          :rules="[{ required: true, message: '请输入问卷标题', trigger: 'blur' }]">
                <el-input v-model.trim="addEditForm.questionnaireName" maxlength="50"
                          placeholder="请输入问卷标题（限50字）" clearable></el-input>
            </el-form-item>
            <el-form-item label="问卷说明：" prop="questionnaireExplain">
                <el-input v-model.trim="addEditForm.questionnaireExplain" type="textarea"
                          :rows="3" maxlength="100"
                          placeholder="请输入问卷说明（限100字）" clearable></el-input>
            </el-form-item>
            <el-form-item label="问卷题目：" prop="detail"
                          :rules="[{ required: true, message: '请添加问卷题目', trigger: 'blur' }]">
                <div v-for="(item, index) in addEditForm.detail" :key="item.id" class="questionItem">
                    <div class="flex_b_c p_all1 m_b2 tit">
                        <div class="flex_1 b_d">题目 {{index+1}}/{{addEditForm.detail.length}}</div>
                        <div class="m_l1">
                            <el-button icon="el-icon-minus" circle :disabled="addEditForm.detail.length == 1" @click="addMinusQuestion(index, 0)"></el-button>
                            <el-button icon="el-icon-plus" circle :disabled="addEditForm.detail.length == 50" @click="addMinusQuestion(index, 1)"></el-button>
                        </div>
                    </div>
                    <el-form-item label="标题" :prop="`detail.${index}.websiteQuestionnaireDetailTitle`"
                                  :rules="[{required: true, message: '请输入标题', trigger: 'blur'}]" label-width="100px">
                        <el-input v-model.trim="item.websiteQuestionnaireDetailTitle" maxlength="100"
                                  placeholder="请输入标题（限100字）" clearable style="width: 70%"></el-input>
                    </el-form-item>
                    <el-form-item label="是否必填" :prop="`detail.${index}.mandatory`"
                                  :rules="[{required: true, message: '请选择', trigger: 'change'}]" label-width="100px">
                        <el-radio-group v-model="item.mandatory">
                            <el-radio label="0">必填</el-radio>
                            <el-radio label="1">非必填</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="题型" :prop="`detail.${index}.websiteQuestionnaireDetailType`"
                                  :rules="[{required: true, message: '请选择题型', trigger: 'change'}]" label-width="100px">
                        <el-radio-group v-model="item.websiteQuestionnaireDetailType" @input="questionTypesChange(item)">
                            <el-radio label="0">单选</el-radio>
                            <el-radio label="1">多选</el-radio>
                            <el-radio label="2">填空题</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="选项" :prop="`detail.${index}.selectDetail`" :rules="[{required: true, message: '请输入选项', trigger: 'blur'}]" label-width="100px" v-if="item.websiteQuestionnaireDetailType != 2">
                        <el-form-item :prop="`detail.${index}.selectDetail.${indexC}.websiteQuestionnaireSelect`" :rules="[{required: true, message: '请输入选项内容', trigger: 'blur'}]" label-width="0px" v-for="(itemC, indexC) in item.selectDetail" :key="itemC.id">
                            <div class="flex_l_c">
                                <el-input v-model.trim="itemC.websiteQuestionnaireSelect" maxlength="50" placeholder="请输入选项（限50字）" clearable style="width: 70%"></el-input>
                                <div class="m_l1">
                                    <el-button icon="el-icon-minus" circle :disabled="item.selectDetail.length == 1" @click="addMinusOptions(indexC, 0, index)"></el-button>
                                    <el-button icon="el-icon-plus" circle :disabled="item.selectDetail.length == 20" @click="addMinusOptions(indexC, 1, index)"></el-button>
                                </div>
                            </div>
                        </el-form-item>
                    </el-form-item>
                    <el-form-item class="p_b2" label="选项" :prop="`detail.${index}.websiteQuestionnaireDetailLength`" :rules="[{required: true, message: '请输入选项', trigger: 'blur'}]" label-width="60px" v-else>
                        <el-input class="m_r1" v-model.trim="item.websiteQuestionnaireDetailLength" maxlength="4" placeholder="输入字数限制，限10~1000" @blur="wordsChange(item)" clearable style="width: 70%"></el-input>字
                    </el-form-item>
                </div>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="visible = false">关闭</el-button>
                <el-button size="small" type="primary" @click="submit()" v-noMoreClick>提交</el-button>
            </span>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                // 新增、编辑
                visible: false,
                addEditForm: {
                    id: '',
                    questionnaireName: '',
                    questionnaireExplain: '',
                    state: '1',
                    detail: []
                },
                questionItem: {},
                method: 0,
            }
        },
        methods: {
            init(row, method) {
                this.addEditForm.id = ''
                this.addEditForm.detail = []
                this.visible = true
                this.method = method
                if (method == 1) {
                    this.getDetail(row.id);
                }
                this.$nextTick(() => {
                    this.$refs.addEditForm.resetFields();
                    if(method == 0) {
                        this.clearQuestionItem()
                        this.addEditForm.detail.push(this.questionItem);
                    }
                })
            },
            // 编辑获取详情
            getDetail(id) {
                this.$axios(this.api.website.websitequestionnaireGetById + '/' + id).then(res => {
                    if (res.status) {
                        this.addEditForm = res.data
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            // 清空题目
            clearQuestionItem() {
                this.questionItem = {
                    websiteQuestionnaireDetailTitle: '',
                    mandatory: '0',
                    websiteQuestionnaireDetailType: '',
                    websiteQuestionnaireDetailLength: null,
                    selectDetail: [
                        {

                            websiteQuestionnaireSelect: ''
                        }
                    ],
                }
            },
            // 加减题目 type：0减 1加
            addMinusQuestion(index, type) {
                this.clearQuestionItem()
                if(type == 0) {
                    this.addEditForm.detail.splice(index, 1);
                } else {
                    this.addEditForm.detail.splice(index+1, 0, this.questionItem);
                }
            },
            // 加减选项 type：0减 1加
            addMinusOptions(indexC, type, index) {
                let item = {websiteQuestionnaireSelect: ''}
                if(type == 0) {
                    this.addEditForm.detail[index].selectDetail.splice(indexC, 1);
                } else {
                    this.addEditForm.detail[index].selectDetail.splice(indexC+1, 0, item);
                }
            },
            // 题型改变
            questionTypesChange(item) {
                if(item.websiteQuestionnaireDetailType == 2) {
                    this.$set(item, 'selectDetail', [])
                    this.$set(item, 'websiteQuestionnaireDetailLength', null)
                } else {
                    this.$set(item, 'selectDetail', [
                        {
                            websiteQuestionnaireSelect: ''
                        }
                    ])
                }
            },
            // 提交
            submit() {
                this.$refs['addEditForm'].validate((valid) => {
                    if (valid) {
                        this.$axios(this.api.website.websitequestionnaireSave, this.addEditForm, 'post').then(res => {
                            if (res.status) {
                                this.$message.success('提交成功')
                                this.visible = false
                                this.$emit('refreshDataList')
                            } else {
                                this.$message.error(res.msg)
                            }
                        })
                    }
                })
            },
            // 填空题字数限制修改
            wordsChange(item) {
                let value = item.websiteQuestionnaireDetailLength.replace(/\D/g,'')
                if(value > 1000 && value != '') {
                    this.$message.error('字数限制必须小于等于1000')
                    this.$set(item, 'websiteQuestionnaireDetailLength', 1000)
                } else if(value < 10 && value != '') {
                    this.$message.error('字数限制必须大于等于10')
                    this.$set(item, 'websiteQuestionnaireDetailLength', 10)
                } else {
                    this.$set(item, 'websiteQuestionnaireDetailLength', value)
                }
            },
        },
    }
</script>

<style scoped>
    .questionItem {
        background: #eeeeee;
    }

    .questionItem .tit {
        background: #dbdbdb;
    }
</style>
