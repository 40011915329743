<template>
    <el-dialog title="问卷调查明细" width="60%" :close-on-click-modal="false" :visible.sync="visible">
        <div class="text_center tit">{{info.questionnaireName}}</div>
        <div class="text_center explain m_b2">{{info.questionnaireExplain}}</div>
        <div v-for="(item, index) in info.detail" :key="index" class="m_b2">
            <div class="m_b1">{{index+1}}/{{info.detail.length}}、{{item.websiteQuestionnaireDetailTitle}} <span v-if="item.websiteQuestionnaireDetailType == 1">（{{item.websiteQuestionnaireDetailType == 1 ? '多选' : ''}}）</span></div>
            <el-radio label="0" v-if="item.websiteQuestionnaireDetailType == 0"
                      v-for="(itemC, indexC) in item.selectDetail" :key="indexC">{{itemC.websiteQuestionnaireSelect}}
            </el-radio>
            <el-checkbox v-if="item.websiteQuestionnaireDetailType == 1" v-for="(itemC, indexC) in item.selectDetail"
                         :key="indexC">{{itemC.websiteQuestionnaireSelect}}
            </el-checkbox>
            <el-input type="textarea" placeholder="请输入内容" :maxlength="item.websiteQuestionnaireDetailLength"
                      show-word-limit v-if="item.websiteQuestionnaireDetailType == 2"></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="visible = false">取消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                visible: false,
                info: {},
            }
        },
        methods: {
            init(id) {
                this.visible = true
                this.getInfo(id);
            },
            getInfo(id) {
                this.$axios(this.api.website.websitequestionnaireGetById + '/' + id).then(res => {
                    if (res.status) {
                        this.info = res.data
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
        },
    }
</script>

<style scoped>
    .tit {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .explain {
        font-size: 12px;
    }

    .el-radio {
        margin-bottom: 10px;
        white-space: normal !important;
    }
</style>
