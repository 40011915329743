<template>
    <div class="page">
        <el-row class="query-form p_b1">
            <el-col :span="16">
                <el-input size="small" v-model="searchForm.questionnaireName" placeholder="问卷标题" maxlength="50" class="m_r1"
                          style="width: 20%" clearable></el-input>
                <el-select size="small" v-model="searchForm.createUser" placeholder="请选择创建人" class="m_r1" style="width: 20%" clearable>
                    <el-option
                            v-for="item in createUserOpt"
                            :key="item.createUserId"
                            :label="item.createUser"
                            :value="item.createUserId">
                    </el-option>
                </el-select>
                <el-date-picker class="m_r1" size="small"
                                value-format="yyyy-MM-dd"
                                format="yyyy-MM-dd"
                                v-model="createTime"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="创建开始日期"
                                end-placeholder="创建结束日期" style="width: 35%">
                </el-date-picker>
                <el-button type="primary" @click="queryData(1)" size="small" icon="el-icon-search">查询</el-button>
                <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
            </el-col>
            <el-col :span="8" class="text_right">
                <el-button type="primary" v-if="hasPermission('questionnaire:add')"
                           @click="addEdit(null, 0)" size="small">新增</el-button>
                <el-button type="primary" v-if="hasPermission('questionnaire:delete')"
                           :disabled="dataListSelections.length <= 0" @click="del()" size="small">删除
                </el-button>
            </el-col>
        </el-row>
        <div class="bg-white">
            <el-table :data="list"
                      v-loading="loading"
                      @selection-change="selectionChangeHandle"
                      size="small"
                      height="calc(100vh - 280px)" class="table">
                <el-table-column type="selection" :selectable="checkSelectable" width="50" fixed></el-table-column>
                <el-table-column prop="questionnaireName" label="问卷标题" show-overflow-tooltip></el-table-column>
                <el-table-column prop="questionnaireExplain" label="说明" show-overflow-tooltip></el-table-column>
                <el-table-column prop="state" label="状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.state"
                                   @change="statusChange(scope.row)"
                                   :active-value="'0'"
                                   :inactive-value="'1'"
                        ></el-switch>
                    </template>
                </el-table-column>
                <el-table-column prop="createUser" label="创建人" show-overflow-tooltip></el-table-column>
                <el-table-column prop="createTime" label="创建时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="updateTime" label="最后修改时间" width="120" show-overflow-tooltip></el-table-column>
                <el-table-column fixed="right" width="150" label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="hasPermission('questionnaire:view')" type="text" size="mini"
                                   @click="view(scope.row.id)">查看
                        </el-button>
                        <el-button v-if="hasPermission('questionnaire:edit')" :disabled="scope.row.state == '0'" type="text" size="mini"
                                   @click="addEdit(scope.row, 1)">编辑
                        </el-button>
                        <el-button v-if="hasPermission('questionnaire:delete')" :disabled="scope.row.state == '0'" type="text" size="mini"
                                   @click="del([scope.row.id])">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="searchForm.current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="searchForm.size"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <AddForm ref="addForm" @refreshDataList="queryData"></AddForm>
        <Details ref="details"></Details>
    </div>
</template>

<script>
    import AddForm from './addForm'
    import Details from './details'

    export default {
        components: {AddForm, Details},
        data() {
            return {
                createTime:[],
                searchForm: {
                    createStartTime: '',
                    createEndTime: '',
                    createUser: '',
                    questionnaireName: '',
                    current: 1,
                    size: 10,
                },
                createUserOpt: [],
                list: [],
                total: 0,
                loading: false,
                dataListSelections: [],
            }
        },
        mounted() {
            this.queryData(1)
        },
        methods: {
            // 获取数据
            queryData(type) {
                this.sysGetCreateUser()
                if (type == 1) {
                    this.searchForm.current = 1
                }
                this.loading = true
                let param = JSON.parse(JSON.stringify(this.searchForm))
                param.createStartTime = this.createTime ? this.createTime[0] : ''
                param.createEndTime = this.createTime ? this.createTime[1] : ''
                this.$axios(this.api.website.websitequestionnaire, param, 'post').then((res) => {
                    if (res.status) {
                        this.list = res.data.records
                        if (this.list.length == 0 && this.searchForm.current > 1) {
                            this.searchForm.current--
                            this.queryData()
                        }
                        this.total = parseInt(res.data.total)
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.loading = false
                })
            },
            sysGetCreateUser() {
                this.$axios(this.api.website.sysGetCreateUser).then((res) => {
                    if (res.status) {
                        this.createUserOpt = res.data
                    }
                })
            },
            checkSelectable(row) {
                return row.state == '1'
            },
            // 重置
            resetSearch() {
                this.createTime = []
                this.searchForm = {
                    createStartTime: '',
                    createEndTime: '',
                    createUser: '',
                    questionnaireName: '',
                    current: 1,
                    size: 10,
                }
                this.queryData(1)
            },
            // 状态修改
            statusChange(row) {
                this.$axios(this.api.website.openWebsiteQuestionnaire, {id: row.id, state: row.state}, 'put').then((res) => {
                    if (res.status) {
                        this.$message.success('操作成功')
                        this.queryData();
                    } else {
                        this.$message.error(res.msg)
                        this.queryData();
                    }
                })
            },
            // 查看
            view(id) {
                this.$refs.details.init(id)
            },
            // 新增、编辑 row:数据；method：0新增，1编辑
            addEdit(row, method) {
                this.$refs.addForm.init(row, method)
            },
            // 删除
            del(id) {
                let ids = id || this.dataListSelections.map(item => {
                    return item.id
                })
                this.$confirm(`确定删除这条数据吗?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    this.$axios(this.api.website.questionBatchRemove, ids, 'post').then((res) => {
                        if (res.status) {
                            this.$message.success(res.msg)
                            this.queryData();
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                });
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.queryData()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.queryData()
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
        }
    }
</script>

<style scoped>

</style>
